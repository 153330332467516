import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { useAlertPlus } from 'hooks';
import Required from 'components/Required';
import AlertModal from 'components/AlertModal';
import { useUser } from 'context/UserContext';
import ObservationsAlert from 'components/Requests/ObservationAlert';
import { DeudaData, AvailableQuotas, CardData } from 'types/Payment';
import { getDeuda, getAvailableQuotas, createPaymet } from 'features/payments/paymentsAPI';
import { useConfig } from 'context/ConfigContext';

export default function BancoProvincia() {
  const c = useConfig();
  const { getMatricula } = useUser();
  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const formRef = useRef<HTMLFormElement>(null);

  const [deudaData, setDeudaData] = useState<DeudaData>({
    anio: 0,
    concepto: '',
    monto: 0,
    recargo: 0,
    convenio: false,
    formaPago: '',
    opcion: 0,
    montoOriginal: 0,
  });
  const [hasDeuda, setHasDeuda] = useState(false);

  const [availableQuotas, setAvailableQuotas] = useState<AvailableQuotas[]>([{
    id: 1,
    quotas: 1,
    quotas_text: '1 cuota',
  }]);

  const [cardData, setCardData] = useState<CardData>({
    card_holder_name: '',
    card_number: '',
    card_expiration_month: '',
    card_expiration_year: '',
    security_code: '',
    card_quotas: '1',
    card_holder_doc_type: '',
    card_holder_doc_number: '',
  });

  const [isLoading, setLoading] = useState(true);
  const [isBeingPaid, setIsBeingPaid] = useState(false);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://live.decidir.com/static/v2.6.4/decidir.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setHasDeuda(false);

    try {
      getDeuda(matriculateId)
        .then(response => {
          if (response && response.length != 0) {
            const regex = /^ANUAL\/\d{4}$/;
            const deudaAnual = response.find(item => regex.test(item.concepto));

            if (deudaAnual) {
              setDeudaData(deudaAnual);
              setHasDeuda(true);
            }
          }
        });

      getAvailableQuotas()
        .then(response => {
          if (response && response.length != 0) {
            setAvailableQuotas(response);
          }
        });
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFormValidation = () => {
    let toValidate = [
      ...document.getElementsByClassName('form-control'),
    ] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else {
        field.classList.remove('is-invalid');
      }
    });

    if (missing) {
      alert(
        'Todos los campos marcados con * son obligatorios. ' +
          'Por favor complete los faltantes antes de enviar la solicitud.',
      );
      return false;
    }

    return true;
  };

  const tokenResponseHandler = (status: any, response: any) => {
    if (status == 200 || status == 201) {
      if (response.id && response.bin) {
        const paymentData = {
          token: response.id,
          bin: response.bin,
          cuotas: cardData.card_quotas,
          ...deudaData,
        };

        try {
          createPaymet(paymentData)
            .then(response => {
              if (response?.status) {
                alert('Pago realizado correctamente. ' +
                  'Se verá reflejado en las próximas 72 horas.');
              } else {
                alert('Hubo un problema al realizar el pago. ' +
                  'Por favor, intente nuevamente más tarde.');
                setIsBeingPaid(false);
              }
              setLoading(false);
            });
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      switch (status) {
        case 422:
          alert('Datos de la tarjeta erroneos. ' +
            'Revise los datos ingresados y vuelva a intentar.');
          break;
        default:
          alert('Hubo un problema al realizar el pago. ' +
            'Por favor, intente nuevamente más tarde.');
      }
    }
  };

  const handlePaymentGeneration = () => {
    setLoading(true);
    setIsBeingPaid(true);

    if (!handleFormValidation()) {
      setLoading(false);
      setIsBeingPaid(false);
      return;
    }

    try {
      const prismaURL = c.getSetting<string>('prisma.url');
      const publicApiKey = c.getSetting<string>('prisma.public_key');

      console.debug(prismaURL);
      console.debug(publicApiKey);

      const decidir = new (window as any).Decidir(prismaURL);
      decidir.setPublishableKey(publicApiKey);

      decidir.createToken(formRef.current, tokenResponseHandler);
    } catch (err) {
      console.error(err);
      // alert(err);
    } finally {
      // setLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const name = target.name as keyof CardData;
    const value = target.value;
    let newValue = cardData[name];

    if ([
      'card_number',
      'card_expiration_month',
      'additicard_expiration_yearonal_id',
      'security_code',
      'card_holder_doc_number',
    ].includes(name)) {
      if (/^\d*$/.test(value)) {
        newValue = value;
      }
    } else {
      newValue = value;
    }

    setCardData({
      ...cardData,
      [name]: newValue,
    });
  };

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">
                    Pago Matrícula Banco Provincia
                  </CardTitle>
                </Col>
              </Row>
              <ObservationsAlert observations={[]} />
            </CardHeader>
            { !isBeingPaid
              ? hasDeuda
                ? <CardBody>
                    <Row className="mb-2">
                      <Col md='2' sm='12'><strong>Concepto</strong></Col>
                      <Col md='10' sm='12'>{deudaData.concepto}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md='2' sm='12'><strong>Monto</strong></Col>
                      <Col md='10' sm='12'>$ {(deudaData.montoOriginal + deudaData.recargo).toFixed(2)}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md='2' sm='12'><strong>Año</strong></Col>
                      <Col md='10' sm='12'>{deudaData.anio}</Col>
                    </Row>
                    <Form innerRef={formRef}>
                      <FormGroup>
                        <Row form>
                          <Col sm={8}>
                            <Label for="card_holder_name">
                              Nombre Completo (como aparece en la tarjeta) <Required />
                            </Label>
                            <Input
                              id="card_holder_name"
                              name="card_holder_name"
                              value={cardData.card_holder_name}
                              onChange={handleInputChange}
                              data-decidir="card_holder_name"
                              required
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col sm={8}>
                            <Label for="card_number">
                              Número de Tarjeta <Required />
                            </Label>
                            <Input
                              id="card_number"
                              name="card_number"
                              value={cardData.card_number}
                              onChange={handleInputChange}
                              data-decidir="card_number"
                              required
                              maxLength={16}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col sm={2}>
                            <Label for="card_expiration_month">
                              Mes de vencimiento <Required />
                            </Label>
                            <Input
                              id="card_expiration_month"
                              name="card_expiration_month"
                              value={cardData.card_expiration_month}
                              onChange={handleInputChange}
                              data-decidir="card_expiration_month"
                              required
                              maxLength={2}
                            />
                          </Col>
                          <Col sm={2}>
                            <Label for="card_expiration_year">
                              Año de vencimiento <Required />
                            </Label>
                            <Input
                              id="card_expiration_year"
                              name="card_expiration_year"
                              value={cardData.card_expiration_year}
                              onChange={handleInputChange}
                              data-decidir="card_expiration_year"
                              required
                              maxLength={2}
                            />
                          </Col>
                          <Col sm={4}>
                            <Label for="security_code">
                              Código de Seguridad <Required />
                            </Label>
                            <Input
                              id="security_code"
                              name="security_code"
                              value={cardData.security_code}
                              onChange={handleInputChange}
                              data-decidir="security_code"
                              required
                              maxLength={4}
                            />
                          </Col>
                        </Row>
                        { availableQuotas.length
                          ? <Row form>
                              <Col sm={8}>
                                <Label for="card_quotas">
                                  Cuotas <Required />
                                </Label>
                                <Input
                                  id="card_quotas"
                                  name="card_quotas"
                                  type="select"
                                  className="custom-select"
                                  value={cardData.card_quotas}
                                  onChange={handleInputChange}
                                  data-decidir="card_quotas"
                                  required
                                >
                                  { availableQuotas.map(item => {
                                    return (
                                      <option key={item.id} value={item.quotas}>
                                        {item.quotas_text}
                                      </option>
                                    );
                                  }) }
                                </Input>
                              </Col>
                            </Row>
                          : null
                        }
                        <Row form>
                          <Col sm={8}>
                            <Label for="card_holder_doc_type">
                              Tipo de Documento <Required />
                            </Label>
                            <Input
                              id="card_holder_doc_type"
                              name="card_holder_doc_type"
                              type="select"
                              className="custom-select"
                              value={cardData.card_holder_doc_type}
                              onChange={handleInputChange}
                              data-decidir="card_holder_doc_type"
                              required
                            >
                              <option value="DNI">
                                DNI
                              </option>
                              <option value="CUIL">
                                CUIL
                              </option>
                              <option value="CUIT">
                                CUIT
                              </option>
                            </Input>
                          </Col>
                        </Row>
                        <Row form>
                          <Col sm={8}>
                            <Label for="card_holder_doc_number">
                              Número de Documento <Required />
                            </Label>
                            <Input
                              id="card_holder_doc_number"
                              name="card_holder_doc_number"
                              value={cardData.card_holder_doc_number}
                              onChange={handleInputChange}
                              data-decidir="card_holder_doc_number"
                              required
                              maxLength={12}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <div className="text-center">
                          <Button
                            type="button"
                            className="btn-round"
                            onClick={handlePaymentGeneration}
                            disabled={isLoading}
                          >
                            Realizar Pago
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </CardBody>
                : <CardBody>
                    <Row className="mb-2">
                      <Col md='12' sm='12'><strong>NO POSEE DEUDA ANUAL</strong></Col>
                    </Row>
                  </CardBody>
              : <CardBody>
                  <Row className="mb-2">
                    <Col md='12' sm='12'><strong>EL PAGO ESTÁ SIENDO PROCESADO</strong></Col>
                  </Row>
                </CardBody>
            }
            <AlertModal
              isOpen={showAlert}
              message={message ?? ''}
              onClose={alertCallback}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
