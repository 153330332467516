import config from 'config';
import { getCookie } from 'helpers';
import { DeudaData, AvailableQuotas, PaymentResponseData } from 'types/Payment';
import { APIResponse } from 'types/Request';

export interface CreatePaymetOptions {
  token: string,
  bin: string,
  anio: number,
  concepto: string,
  monto: number,
  recargo: number,
  convenio: boolean,
  formaPago: string,
  opcion: number,
  montoOriginal: number
}

export async function getDeuda(matriculateId: string): Promise<DeudaData[] | undefined> {
  try {
    const url = new URL(`${ config.baseUrl }/matriculates/${ matriculateId }/debt`);

    type Response = APIResponse<DeudaData[]>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken'),
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;

    if (content.status !== 'ok') {
      if (content.code === 'CONNECTION_ERROR') {
        return;
      }

      throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
        'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (1)');
    }

    return content.payload ?? undefined;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
      'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (2)');
  }
}

export async function getAvailableQuotas(): Promise<AvailableQuotas[] | undefined> {
  try {
    const url = new URL(`${ config.baseUrl }/bancoprovincia/availableQuotas`);

    type Response = APIResponse<AvailableQuotas[]>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken'),
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;

    if (content.status !== 'ok') {
      if (content.code === 'CONNECTION_ERROR') {
        return;
      }

      throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
        'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (1)');
    }

    return content.payload ?? undefined;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
      'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (2)');
  }
}

export async function createPaymet(options: CreatePaymetOptions): Promise<PaymentResponseData | undefined> {
  try {
    const url = new URL(`${ config.baseUrl }/bancoprovincia/createPaymet`);

    type Response = APIResponse<PaymentResponseData>

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken'),
      },
      body: JSON.stringify(options),
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;

    return content.payload ?? undefined;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar realizar el pago. ' +
      'Temporalmente no se podrá enviar la solicitud.');
  }
}
